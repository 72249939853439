// plugins/no-screenshot.js
export default () => {
  document.addEventListener('keydown', (e) => {
    if (e.key === 'PrintScreen') {
      alert('Chụp màn hình bị cấm!');
      e.preventDefault();
      document.body.classList.add('screenshotting');
    }
  });

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      document.body.classList.add('screenshotting');
    } else {
      document.body.classList.remove('screenshotting');
    }
  });
}
