export let ADMIN = 'is-admin';
export let AVATAR = 'avatar';
export const TOKEN_KEY = 'mottruyen-token'
export const TOKEN_KEY_REFRESH = 'mottruyen-token-refresh'
export const TOKEN_HEADER_KEY = 'Authorization'
export const USER_NAME = 'username'
export const USER_ID = 'user_id'
export const AUTHOR_ID = 'author_id'
export const EMAIL = 'email'
export const NAME = 'name'
export const PHONE = 'phone'
export const REF_CODE = 'ref_code'

export const FONT_SIZE = 'font_size'
export const BACKGROUND_CHAPTER = 'background_chapter'

export const HISTORY_STORY = 'history_story'
export const COLOR = 'color';

export const MENU_ADMIN = [
  // {
  //   "title": "Hệ thống",
  //   "sub": [
  //     {
  //       "name": "admin",
  //       "title": "Dashboard",
  //       "icons": "",
  //     },
  //     {
  //       "name": "admin-config-money",
  //       "title": "Cài đặt tiền thưởng",
  //       "icons": "",
  //     },
  //   ]
  // },
  {
    "title": "Quản lý truyện",
    "sub": [
      {
        "name": "admin-danh-sach-truyen",
        "title": "Danh sách truyện",
        "icon": "",
      }
    ]
  }
]
export const MENU_USER = [
  {
    "title": "Sự kiện",
    "sub": [
      {
        "name": "admin-danh-sach-truyen",
        "title": "Sự kiện đang diễn ra",
        "icon": "",
      }
    ]
  }
]
export const BANK_LIST = [
  {
    "en_name": "An Binh Commercial Joint stock  Bank",
    "vn_name": "Ngân hàng An Bình",
    "bankId": "970425",
    "atmBin": "970425",
    "cardLength": 16,
    "shortName": "ABBank",
    "bankCode": "323",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Asia Commercial Bank",
    "vn_name": "Ngân hàng Á Châu",
    "bankId": "970416",
    "atmBin": "970416",
    "cardLength": 0,
    "shortName": "ACB",
    "bankCode": "307",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Vienam Bank for Agriculture and Rural Development",
    "vn_name": "Ngân hàng NN & PTNT VN",
    "bankId": "970405",
    "atmBin": "970499",
    "cardLength": 16,
    "shortName": "Agribank, VBARD",
    "bankCode": "204",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "ANZ Bank",
    "vn_name": "Ngân hàng ANZ Việt Nam",
    "cardLength": 0,
    "shortName": "ANZ",
    "bankCode": "602",
    "napasSupported": false
  },
  {
    "en_name": "BANGKOK  BANK",
    "vn_name": "BANGKOK  BANK",
    "cardLength": 0,
    "shortName": "BANGKOK  BANK",
    "bankCode": "612",
    "napasSupported": false
  },
  {
    "en_name": "VietNam national Financial switching Joint Stock Company",
    "vn_name": "Công ty cổ phần chuyển mạch tài chính quốc gia Việt Nam",
    "cardLength": 0,
    "shortName": "Banknetvn",
    "bankCode": "401",
    "napasSupported": false
  },
  {
    "en_name": "Baoviet Joint Stock Commercial Bank",
    "vn_name": "Ngân hàng TMCP Bảo Việt",
    "bankId": "970438",
    "atmBin": "970438",
    "cardLength": 20,
    "shortName": "Baoviet Bank",
    "bankCode": "359",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "BANK OF CHINA",
    "vn_name": "BANK OF CHINA",
    "cardLength": 0,
    "shortName": "BC",
    "bankCode": "620",
    "napasSupported": false
  },
  {
    "en_name": "Bank for investment and development of Cambodia HCMC",
    "vn_name": "NH ĐT&PT Campuchia CN HCM",
    "cardLength": 0,
    "shortName": "BIDC HCM",
    "bankCode": "648",
    "napasSupported": false
  },
  {
    "en_name": "Bank for investment and development of Cambodia HN",
    "vn_name": "NH ĐT&PT Campuchia CN Hà Nội",
    "cardLength": 0,
    "shortName": "BIDC HN",
    "bankCode": "638",
    "napasSupported": false
  },
  {
    "en_name": "Bank for Investment and Development of Vietnam",
    "vn_name": "Ngân hàng Đầu tư và Phát triển Việt Nam",
    "bankId": "970418",
    "atmBin": "970418",
    "cardLength": 16,
    "shortName": "BIDV",
    "bankCode": "202",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Bank of Paris and the Netherlands HCMC",
    "vn_name": "BNP Paribas Bank HCM",
    "cardLength": 0,
    "shortName": "BNP Paribas HCM",
    "bankCode": "614",
    "napasSupported": false
  },
  {
    "en_name": "BNP Paribas Ha Noi",
    "vn_name": "Ngan hang BNP Paribas CN Ha Noi",
    "cardLength": 0,
    "shortName": "BNP Paribas HN",
    "bankCode": "657",
    "napasSupported": false
  },
  {
    "en_name": "Bank of Communications",
    "vn_name": "Bank of Communications",
    "cardLength": 0,
    "shortName": "BOC",
    "bankCode": "615",
    "napasSupported": false
  },
  {
    "en_name": "NH BPCEIOM HCMC",
    "vn_name": "Ngân hàng BPCEIOM CN  TP Hồ Chí Minh",
    "cardLength": 0,
    "shortName": "BPCEICOM",
    "bankCode": "601",
    "napasSupported": false
  },
  {
    "en_name": "BANK OF TOKYO - MITSUBISHI UFJ - TP HCM",
    "vn_name": "BANK OF TOKYO - MITSUBISHI UFJ - TP HCM",
    "cardLength": 0,
    "shortName": "BTMU HCM",
    "bankCode": "622",
    "napasSupported": false
  },
  {
    "en_name": "BANK OF TOKYO - MITSUBISHI UFJ - HN",
    "vn_name": "BANK OF TOKYO - MITSUBISHI UFJ - HN",
    "cardLength": 0,
    "shortName": "BTMU HN",
    "bankCode": "653",
    "napasSupported": false
  },
  {
    "en_name": "Credit Agricole Corporate and Investment Bank",
    "vn_name": "Credit Agricole Corporate and Investment Bank",
    "cardLength": 0,
    "shortName": "CACIB",
    "bankCode": "621",
    "napasSupported": false
  },
  {
    "en_name": "Commonwealth Bank of Australia",
    "vn_name": "Commonwealth Bank of Australia",
    "cardLength": 0,
    "shortName": "CBA",
    "bankCode": "643",
    "napasSupported": false
  },
  {
    "en_name": "China Construction Bank Corporation",
    "vn_name": "China Construction Bank Corporation",
    "cardLength": 0,
    "shortName": "CCBC",
    "bankCode": "611",
    "napasSupported": false
  },
  {
    "en_name": "The Chase Manhattan Bank",
    "vn_name": "The Chase Manhattan Bank",
    "cardLength": 0,
    "shortName": "CHASE",
    "bankCode": "627",
    "napasSupported": false
  },
  {
    "en_name": "CIMB Bank Vietnam Limited",
    "vn_name": "Ngân hàng TNHH MTV CIMB Việt Nam",
    "bankId": "422589",
    "atmBin": "422589",
    "cardLength": 0,
    "shortName": "CIMB",
    "bankCode": "661",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "CitiBank HCM",
    "vn_name": "Citi Bank TP HCM",
    "cardLength": 0,
    "shortName": "CitibankHCM",
    "bankCode": "654",
    "napasSupported": false
  },
  {
    "en_name": "Citibank Ha Noi",
    "vn_name": "Citi Bank Ha Noi",
    "cardLength": 0,
    "shortName": "CitibankHN",
    "bankCode": "605",
    "napasSupported": false
  },
  {
    "en_name": "Co-Operation Bank of Viet Nam",
    "vn_name": "Ngân hàng Hợp tác Việt Nam",
    "cardLength": 0,
    "shortName": "COOPBANK",
    "bankCode": "901",
    "napasSupported": false
  },
  {
    "en_name": "The ChinaTrust Commercial Bank HCMC",
    "vn_name": "Ngân hàng CTBC CN TP Hồ Chí Minh",
    "cardLength": 0,
    "shortName": "CTBC",
    "bankCode": "629",
    "napasSupported": false
  },
  {
    "en_name": "Cathay United Bank",
    "vn_name": "Ngân hàng Cathay",
    "cardLength": 0,
    "shortName": "CTU",
    "bankCode": "634",
    "napasSupported": false
  },
  {
    "en_name": "DEUTSCHE BANK",
    "vn_name": "DEUTSCHE BANK",
    "cardLength": 0,
    "shortName": "DB",
    "bankCode": "619",
    "napasSupported": false
  },
  {
    "en_name": "DBS Bank Ltd",
    "vn_name": "DBS Bank Ltd",
    "cardLength": 0,
    "shortName": "DBS",
    "bankCode": "650",
    "napasSupported": false
  },
  {
    "en_name": "Dong A Commercial Joint stock Bank",
    "vn_name": "Ngân hàng Đông Á",
    "bankId": "970406",
    "atmBin": "970406",
    "cardLength": 16,
    "shortName": "Dong A Bank, DAB",
    "bankCode": "304",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Vietnam Export Import Commercial Joint Stock Bank",
    "vn_name": "Ngân hàng Xuất nhập khẩu Việt Nam",
    "bankId": "970431",
    "atmBin": "970431",
    "cardLength": 16,
    "shortName": "Eximbank, EIB",
    "bankCode": "305",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "First Commercial Bank",
    "vn_name": "First Commercial Bank",
    "cardLength": 0,
    "shortName": "FCNB",
    "bankCode": "630",
    "napasSupported": false
  },
  {
    "en_name": "First Commercial Bank Ha Noi",
    "vn_name": "First Commercial Bank Ha Noi",
    "cardLength": 0,
    "shortName": "FCNB HN",
    "bankCode": "608",
    "napasSupported": false
  },
  {
    "en_name": "Global Petro Commercial Joint Stock Bank",
    "vn_name": "Ngân hàng Dầu khí Toàn cầu",
    "bankId": "970408",
    "atmBin": "970408",
    "cardLength": 20,
    "shortName": "GP Bank",
    "bankCode": "320",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Housing Development Bank",
    "vn_name": "Ngân hàng Phát triển TP HCM",
    "bankId": "970437",
    "atmBin": "970437",
    "cardLength": 20,
    "shortName": "HDBank",
    "bankCode": "321",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Hong Leong Bank Viet Nam",
    "vn_name": "Ngân hàng Hong Leong Viet Nam",
    "bankId": "970442",
    "atmBin": "970442",
    "cardLength": 20,
    "shortName": "HLO",
    "bankCode": "603",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Hua Nan Commercial Bank",
    "vn_name": "Hua Nan Commercial Bank",
    "cardLength": 0,
    "shortName": "HNCB",
    "bankCode": "640",
    "napasSupported": false
  },
  {
    "en_name": "The HongKong and Shanghai Banking Corporation",
    "vn_name": "NH TNHH Một Thành Viên HSBC Việt Nam",
    "cardLength": 0,
    "shortName": "HSBC",
    "bankCode": "617",
    "napasSupported": false
  },
  {
    "en_name": "NH The Hongkong and Shanghai",
    "vn_name": "Ngân hàng The Hongkong và Thượng Hải",
    "cardLength": 0,
    "shortName": "HSBC HN",
    "bankCode": "645",
    "napasSupported": false
  },
  {
    "en_name": "Industrial Bank of Korea",
    "vn_name": "Industrial Bank of Korea",
    "cardLength": 0,
    "shortName": "IBK",
    "bankCode": "641",
    "napasSupported": false
  },
  {
    "en_name": "ICB of China CN Ha Noi",
    "vn_name": "ICB of China CN Ha Noi",
    "cardLength": 0,
    "shortName": "ICB",
    "bankCode": "649",
    "napasSupported": false
  },
  {
    "en_name": "Indovina Bank",
    "vn_name": "Indovina Bank",
    "bankId": "970434",
    "atmBin": "888999",
    "cardLength": 0,
    "shortName": "IVB",
    "bankCode": "502",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Kho Bac Nha Nuoc",
    "vn_name": "Kho Bạc Nhà Nước",
    "cardLength": 0,
    "shortName": "KBNN",
    "bankCode": "701",
    "napasSupported": false
  },
  {
    "en_name": "Korea Exchange Bank",
    "vn_name": "Korea Exchange Bank",
    "cardLength": 0,
    "shortName": "KEB",
    "bankCode": "626",
    "napasSupported": false
  },
  {
    "en_name": "Kien Long Commercial Joint Stock Bank",
    "vn_name": "Ngân hàng Kiên Long",
    "bankId": "970452",
    "atmBin": "970452",
    "cardLength": 16,
    "shortName": "Kienlongbank",
    "bankCode": "353",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Kookmin Bank",
    "vn_name": "Ngân hàng Kookmin",
    "cardLength": 0,
    "shortName": "KMB",
    "bankCode": "631",
    "napasSupported": false
  },
  {
    "en_name": "Lien Viet Post Bank",
    "vn_name": "Ngan hàng TMCP Bưu điện Liên Việt",
    "bankId": "970449",
    "atmBin": "970449",
    "cardLength": 0,
    "shortName": "Lienvietbank,  LPB",
    "bankCode": "357",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Maritime Bank",
    "vn_name": "Ngân hàng Hàng Hải Việt Nam",
    "bankId": "970426",
    "atmBin": "970426",
    "cardLength": 16,
    "shortName": "Maritime Bank, MSB",
    "bankCode": "302",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Maybank",
    "vn_name": "Malayan Banking Berhad",
    "cardLength": 0,
    "shortName": "Maybank",
    "bankCode": "609",
    "napasSupported": false
  },
  {
    "en_name": "Military Commercial Joint stock Bank",
    "vn_name": "Ngân hàng Quân Đội",
    "bankId": "970422",
    "atmBin": "970422",
    "cardLength": 16,
    "shortName": "MB",
    "bankCode": "311",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Malayan Banking Berhad",
    "vn_name": "Malayan Banking Berhad",
    "cardLength": 0,
    "shortName": "MBB",
    "bankCode": "635",
    "napasSupported": false
  },
  {
    "en_name": "Mizuho Corporate Bank - TP HCM",
    "vn_name": "Mizuho Corporate Bank - TP HCM",
    "cardLength": 0,
    "shortName": "MCB_HCM",
    "bankCode": "639",
    "napasSupported": false
  },
  {
    "en_name": "Mega ICBC Bank",
    "vn_name": "Mega ICBC Bank",
    "cardLength": 0,
    "shortName": "MICB",
    "bankCode": "623",
    "napasSupported": false
  },
  {
    "en_name": "Mizuho Bank",
    "vn_name": "Mizuho Corporate Bank",
    "cardLength": 0,
    "shortName": "Mizuho Bank",
    "bankCode": "613",
    "napasSupported": false
  },
  {
    "en_name": "Nam A Commercial Joint stock Bank",
    "vn_name": "Ngân hàng Nam Á",
    "bankId": "970428",
    "atmBin": "970428",
    "cardLength": 0,
    "shortName": "Nam A Bank, NAB",
    "bankCode": "306",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "North Asia Commercial Joint Stock Bank",
    "vn_name": "Ngân hàng Bắc Á",
    "bankId": "970409",
    "atmBin": "970409",
    "cardLength": 0,
    "shortName": "NASBank, NASB",
    "bankCode": "313",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "National Citizen Bank",
    "vn_name": "Ngân hàng Quoc Dan",
    "bankId": "970419",
    "atmBin": "970419",
    "cardLength": 16,
    "shortName": "NCB",
    "bankCode": "352",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Oversea - Chinese Banking Corporation",
    "vn_name": "Oversea - Chinese Bank",
    "cardLength": 0,
    "shortName": "OCBC",
    "bankCode": "625",
    "napasSupported": false
  },
  {
    "en_name": "Ocean Bank",
    "vn_name": "Ngân hàng Đại Dương",
    "bankId": "970414",
    "atmBin": "970414",
    "cardLength": 20,
    "shortName": "Ocean Bank",
    "bankCode": "319",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Orient Commercial Joint Stock Bank",
    "vn_name": "Ngân hàng Phương Đông",
    "bankId": "970448",
    "atmBin": "970448",
    "cardLength": 16,
    "shortName": "Oricombank, OCB, PhuongDong Bank",
    "bankCode": "333",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Petrolimex group commercial Joint stock Bank",
    "vn_name": "Ngân hàng Xăng dầu Petrolimex",
    "bankId": "970430",
    "atmBin": "970430",
    "cardLength": 16,
    "shortName": "PG Bank",
    "bankCode": "341",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "PVcombank",
    "vn_name": "NH TMCP Đại Chúng Viet Nam",
    "bankId": "970412",
    "atmBin": "970412",
    "cardLength": 16,
    "shortName": "PVcombank",
    "bankCode": "360",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Quy tin dung co so",
    "vn_name": "Quỹ tín dụng cơ sở",
    "cardLength": 0,
    "shortName": "QTDCS",
    "bankCode": "902",
    "napasSupported": false
  },
  {
    "en_name": "Saigon Thuong Tin Commercial Joint Stock Bank",
    "vn_name": "Ngân hàng Sài Gòn Thương Tín",
    "bankId": "970403",
    "atmBin": "970403",
    "cardLength": 16,
    "shortName": "Sacombank",
    "bankCode": "303",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Saigon Bank for Industry and Trade",
    "vn_name": "Ngân hàng Sài Gòn Công Thương",
    "bankId": "970400",
    "atmBin": "161087",
    "cardLength": 16,
    "shortName": "Saigonbank",
    "bankCode": "308",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "State Bank of Vietnam",
    "vn_name": "Ngân Hàng Nhà Nước",
    "cardLength": 0,
    "shortName": "SBV",
    "bankCode": "101",
    "napasSupported": false
  },
  {
    "en_name": "Saigon Commercial Joint Stock Bank",
    "vn_name": "Ngân hàng TMCP Sài Gòn",
    "bankId": "970429",
    "atmBin": "970429",
    "cardLength": 16,
    "shortName": "SCB",
    "bankCode": "334",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Standard Chartered Bank",
    "vn_name": "Ngân hàng Standard Chartered Bank Việt Nam",
    "cardLength": 0,
    "shortName": "SCBank",
    "bankCode": "604",
    "napasSupported": false
  },
  {
    "en_name": "Standard Chartered Bank HN",
    "vn_name": "Ngân hàng Standard Chartered Bank HN",
    "cardLength": 0,
    "shortName": "SCBank HN",
    "bankCode": "646",
    "napasSupported": false
  },
  {
    "en_name": "The Shanghai Commercial & Savings Bank CN Dong Nai",
    "vn_name": "The Shanghai Commercial & Savings Bank CN Đồng Nai",
    "cardLength": 0,
    "shortName": "SCSB",
    "bankCode": "606",
    "napasSupported": false
  },
  {
    "en_name": "South East Asia Commercial Joint stock  Bank",
    "vn_name": "Ngân hàng TMCP Đông Nam Á",
    "bankId": "970440",
    "atmBin": "970468",
    "cardLength": 16,
    "shortName": "SeABank",
    "bankCode": "317",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Saigon - Hanoi Commercial Joint Stock Bank",
    "vn_name": "Ngân hàng Sài Gòn - Hà Nội",
    "bankId": "970443",
    "atmBin": "970443",
    "cardLength": 16,
    "shortName": "SHB",
    "bankCode": "348",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Shinhan Bank",
    "vn_name": "Ngân hàng TNHH MTV Shinhan Việt Nam",
    "bankId": "970424",
    "atmBin": "970424",
    "cardLength": 0,
    "shortName": "Shinhan Bank",
    "bankCode": "616",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "The Siam Commercial Public Bank",
    "vn_name": "Ngân hàng The Siam Commercial Public",
    "cardLength": 0,
    "shortName": "SIAM",
    "bankCode": "600",
    "napasSupported": false
  },
  {
    "en_name": "Sumitomo Mitsui Banking Corporation HCMC",
    "vn_name": "Sumitomo Mitsui Banking Corporation HCM",
    "cardLength": 0,
    "shortName": "SMBC",
    "bankCode": "636",
    "napasSupported": false
  },
  {
    "en_name": "Sumitomo Mitsui Banking Corporation HN",
    "vn_name": "Sumitomo Mitsui Banking Corporation HN",
    "cardLength": 0,
    "shortName": "SMBC HN",
    "bankCode": "936",
    "napasSupported": false
  },
  {
    "en_name": "SinoPac Bank",
    "vn_name": "Ngân hàng SinoPac",
    "cardLength": 0,
    "shortName": "SPB",
    "bankCode": "632",
    "napasSupported": false
  },
  {
    "en_name": "Vietnam Technological and Commercial Joint stock Bank",
    "vn_name": "Ngân hàng Kỹ thương Việt Nam",
    "bankId": "970407",
    "atmBin": "970407",
    "cardLength": 16,
    "shortName": "Techcombank, TCB",
    "bankCode": "310",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Taipei Fubon Commercial Bank Ha Noi",
    "vn_name": "Taipei Fubon Commercial Bank Ha Noi",
    "cardLength": 0,
    "shortName": "TFCBHN",
    "bankCode": "642",
    "napasSupported": false
  },
  {
    "en_name": "Taipei Fubon Commercial Bank TP Ho Chi Minh",
    "vn_name": "Taipei Fubon Commercial Bank TP Ho Chi Minh",
    "cardLength": 0,
    "shortName": "TFCBTPHCM",
    "bankCode": "651",
    "napasSupported": false
  },
  {
    "en_name": "United Oversea Bank",
    "vn_name": "United Oversea Bank",
    "bankId": "970458",
    "atmBin": "970458",
    "cardLength": 0,
    "shortName": "UOB",
    "bankCode": "618",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Vietnam Bank for Social Policies",
    "vn_name": "Ngân hàng Chính sách xã hội Việt Nam",
    "cardLength": 0,
    "shortName": "VBSP",
    "bankCode": "207",
    "napasSupported": false
  },
  {
    "en_name": "Vietnam Development Bank",
    "vn_name": "Ngân hàng Phát triển Việt Nam",
    "cardLength": 0,
    "shortName": "VDB",
    "bankCode": "208",
    "napasSupported": false
  },
  {
    "en_name": "Vietnam International Commercial Joint Stock Bank",
    "vn_name": "Ngân hàng Quốc tế",
    "bankId": "970441",
    "atmBin": "970441",
    "cardLength": 0,
    "shortName": "VIBank, VIB",
    "bankCode": "314",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "VID public",
    "vn_name": "Ngân hàng VID Public",
    "bankId": "970439",
    "atmBin": "970439",
    "cardLength": 16,
    "shortName": "VID public",
    "bankCode": "501",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Ngan hang Viet Hoa",
    "vn_name": "Ngân hàng Việt Hoa",
    "cardLength": 0,
    "shortName": "Viet Hoa Bank",
    "bankCode": "324",
    "napasSupported": false
  },
  {
    "en_name": "Viet A Commercial Joint Stock Bank",
    "vn_name": "Ngân hàng Việt Á",
    "bankId": "970427",
    "atmBin": "970427",
    "cardLength": 0,
    "shortName": "VietA Bank, VAB",
    "bankCode": "355",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Vietnam Thương tin Commercial Joint Stock Bank",
    "vn_name": "Ngân hàng Việt Nam Thương Tín",
    "bankId": "970433",
    "atmBin": "970433",
    "cardLength": 16,
    "shortName": "Vietbank",
    "bankCode": "356",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "BanViet Commercial Jont stock Bank",
    "vn_name": "NHTMCP Bản Việt",
    "bankId": "970454",
    "atmBin": "970454",
    "cardLength": 16,
    "shortName": "VietCapital Bank",
    "bankCode": "327",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Joint Stock Commercial Bank for Foreign Trade of Vietnam",
    "vn_name": "Ngân hàng TMCP Ngoại Thương",
    "bankId": "970436",
    "atmBin": "970436",
    "cardLength": 0,
    "shortName": "Vietcombank, VCB",
    "bankCode": "203",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Vietnam Joint Stock Commercial Bank for Industry and Trade",
    "vn_name": "Ngân hàng công thương Việt Nam",
    "bankId": "970415",
    "atmBin": "970415",
    "cardLength": 16,
    "shortName": "Vietinbank",
    "bankCode": "201",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Vietnam Construction Bank",
    "vn_name": "NH TMCP Xây dựng Việt Nam",
    "cardLength": 0,
    "shortName": "VNCB",
    "bankCode": "339",
    "napasSupported": false
  },
  {
    "en_name": "Vietnam prosperity Joint stock commercial Bank",
    "vn_name": "Ngân hàng Thương mại cổ phần Việt Nam Thịnh Vượng",
    "bankId": "970432",
    "atmBin": "970432",
    "cardLength": 16,
    "shortName": "VPBank",
    "bankCode": "309",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Vietnam - Russia Bank",
    "vn_name": "Ngân hàng Liên doanh Việt Nga",
    "bankId": "970421",
    "atmBin": "970421",
    "cardLength": 16,
    "shortName": "VRB",
    "bankCode": "505",
    "type": "ACC",
    "napasSupported": true
  },
  {
    "en_name": "Ngan hang Vung Tau",
    "vn_name": "Ngân hàng Vũng Tàu",
    "cardLength": 0,
    "shortName": "Vung Tau",
    "bankCode": "315",
    "napasSupported": false
  },
  {
    "en_name": "Woori BANK HCMC",
    "vn_name": "NH Woori HCM",
    "cardLength": 0,
    "shortName": "WHHCM",
    "bankCode": "637",
    "napasSupported": false
  },
  {
    "en_name": "WOORI BANK Hanoi",
    "vn_name": "WOORI BANK Hà Nội",
    "bankId": "970457",
    "atmBin": "970457",
    "cardLength": 0,
    "shortName": "WHHN",
    "bankCode": "624",
    "type": "ACC",
    "napasSupported": true
  }
]

