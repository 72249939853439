

import StaticData from "@/utils/static";

export default {
  data() {
    return {
      version: '4.0.1'
    }
  },
  mounted() {
    this.version = StaticData.version()
  },
}
