
export default {
  data() {
    return {
      shouldShowAd: false
    };
  },
  mounted() {
    this.checkAdStatus();
  },
  methods: {
    checkAdStatus() {
      if (process.client) {
        const accountPackage = this.$localStorage.getAccountPackage();
        if (accountPackage && accountPackage.type !== 'PREMIUM') {
          this.shouldShowAd = true;
        } else {
          this.shouldShowAd = false;
        }
      }
    }
  }
}
