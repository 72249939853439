// plugins/localStorage.js
import CryptoJS from 'crypto-js';

const SECRET_KEY = 'A53FFAE97953A92C2E8E59FA4DF46'; // Nên được lưu trữ an toàn

export default ({ app }, inject) => {
  if (process.client) {
    inject('localStorage', {
      setAccountPackage(type, expirationArray) {
        try {
          const expirationDate = arrayToDate(expirationArray);
          const accountPackage = {
            type,
            expiration: expirationDate.toISOString() // Lưu trữ dưới dạng ISO string
          };

          // Mã hóa dữ liệu
          const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(accountPackage), SECRET_KEY).toString();
          localStorage.setItem('accountPackage', encryptedData);
          console.log('Account package saved.');
        } catch (error) {
          console.error('Error encrypting data:', error);
        }
      },

      getAccountPackage() {
        try {
          const encryptedData = localStorage.getItem('accountPackage');

          if (encryptedData) {
            // Giải mã dữ liệu
            const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            if (decryptedData && decryptedData.type && decryptedData.expiration) {
              // Kiểm tra thời gian hết hạn
              const currentDate = new Date();
              const expirationDate = new Date(decryptedData.expiration);

              if (expirationDate > currentDate) {
                return {
                  type: decryptedData.type,
                  expiration: expirationDate
                };
              } else {
                // Xóa dữ liệu hết hạn
                localStorage.removeItem('accountPackage');
                return null;
              }
            } else {
              console.error('Invalid decrypted data format.');
              return null;
            }
          } else {
            return null;
          }
        } catch (error) {
          console.error('Error decrypting data:', error);
          return null;
        }
      },
      removeAccountPackage() {
        try {
          localStorage.removeItem('accountPackage');
          console.log('Account package removed.');
        } catch (error) {
          console.error('Error removing data:', error);
        }
      }
    });
  }
};

// Chuyển đổi mảng thời gian thành đối tượng Date
function arrayToDate(array) {
  return new Date(array[0], array[1] - 1, array[2], array[3], array[4], array[5], array[6]);
}
