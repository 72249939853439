
import {getAdminStatus, removeToken} from "@/utils/auth";

export default {
  components: {
  },
  props: {
    page: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isAdmin: false,
    }
  },
  mounted() {
    this.isAdmin = getAdminStatus();
  },
  methods: {
    logout() {
      removeToken();
      this.logged = false;
      window.location.href = '/';
        this.$localStorage.removeAccountPackage();
    },
  }
}
