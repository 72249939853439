import * as constants from './constants'
import {EMAIL, FONT_SIZE, NAME, REF_CODE, USER_ID} from "./constants";

const ISSERVER = typeof window === "undefined";
export function getToken() {
  if (!ISSERVER)
  return localStorage.getItem(constants.TOKEN_KEY)
}

export function setToken(token) {
  if (!ISSERVER)
    return localStorage.setItem(constants.TOKEN_KEY, token)
}

export function setFontSize(fs) {
  if (!ISSERVER)
    return localStorage.setItem(constants.FONT_SIZE, fs)
}

export function getFontSize() {
  if (!ISSERVER)
  return localStorage.getItem(constants.FONT_SIZE)
}

export function getHistoryStory() {
  if (!ISSERVER)
  return localStorage.getItem(constants.HISTORY_STORY)
}

export function setHistoryStory(map) {
  if (!ISSERVER)
  return localStorage.setItem(constants.HISTORY_STORY, map)
}

export function setBackgroundChapter(bg) { //rgba(255,255,255,0.6)
  if (bg === 'black') {
    if (!ISSERVER)
    localStorage.setItem(constants.COLOR, bg)
  }
  return localStorage.setItem(constants.BACKGROUND_CHAPTER, bg)
}

export function getBackgroundChapter() {
  if (!ISSERVER)
  return localStorage.getItem(constants.BACKGROUND_CHAPTER)
}

export function setRefreshToken(token) {
  if (!ISSERVER)
  return localStorage.setItem(constants.TOKEN_KEY_REFRESH, token)
}

export function removeToken() {
  if (!ISSERVER)
  localStorage.removeItem(constants.TOKEN_KEY)
  localStorage.removeItem(constants.TOKEN_KEY_REFRESH)
  localStorage.removeItem(constants.USER_ID)
  localStorage.removeItem(constants.USER_NAME)
  localStorage.removeItem(constants.REF_CODE)
  localStorage.removeItem(constants.EMAIL)
  localStorage.removeItem(constants.NAME)
  localStorage.removeItem(constants.AVATAR)
  localStorage.removeItem(constants.ADMIN)
  localStorage.removeItem(constants.PHONE)
}


export function setUserName(username) {
  if (!ISSERVER)
  return localStorage.setItem(constants.USER_NAME, username)
}
export function getUserName() {
  if (!ISSERVER) {
    return localStorage.getItem(constants.USER_NAME)
  }
}
export function setUserInfo(userInfo) {
  if (!ISSERVER)
  localStorage.setItem(constants.USER_NAME, userInfo.username)
  localStorage.setItem(constants.EMAIL, userInfo.email)
  localStorage.setItem(constants.REF_CODE, userInfo.refCode)
  localStorage.setItem(constants.NAME, userInfo.name)
  localStorage.setItem(constants.USER_ID, userInfo.id)
  localStorage.setItem(constants.AUTHOR_ID, userInfo.authorId)
  localStorage.setItem(constants.AVATAR, userInfo.avatar === undefined || userInfo.avatar === null ? null : userInfo.avatar)
}
export function setAdminStatus(userInfo) {
  if (!ISSERVER)
  localStorage.setItem(constants.ADMIN, userInfo.isAdmin)
}
export function getAdminStatus() {
  if (!ISSERVER)
  return localStorage.getItem(constants.ADMIN)
}
export function getAuthorId() {
  if (!ISSERVER)
    return localStorage.getItem(constants.AUTHOR_ID)
}
export function getEmail() {
  if (!ISSERVER)
  return localStorage.getItem(constants.EMAIL)
}
export function getRefCode() {
  if (!ISSERVER)
  return localStorage.getItem(constants.REF_CODE)
}
export function getName() {
  if (!ISSERVER)
  return localStorage.getItem(constants.NAME)
}

export function getAvatar() {
  if (!ISSERVER)
    if (localStorage.getItem(constants.AVATAR) != null) {
      if (localStorage.getItem(constants.AVATAR).length > 10)
        return localStorage.getItem(constants.AVATAR)
    }
}
export function getUserId() {
  if (!ISSERVER)
    return localStorage.getItem(constants.USER_ID)
}

export function getUserFromToken (key) {
  let token = getToken();
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  if(key){
    return JSON.parse(jsonPayload)[key];
  }
  return JSON.parse(jsonPayload);
}

export function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);

}

export function getUserType(){
  try{
    var data = parseJwt(getToken());
    return data.type;
  } catch (e){
  }
  return null
}
