import axios from "axios";
import {getToken, removeToken} from "@/utils/auth";
import Vue from 'vue'

const api = axios.create()
api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return error.response.data;
});



export default class RequestUtil {
  static post(path, body, message = null) {
    return api.post(path, body, {
      baseURL: process.env.NUXT_ENV_BASE_API,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type':  'application/json',
      }
    }).then(function (response) {
      return RequestUtil.handleSuccess(response, message);
    }).catch(function (error) {
      if (error.response && error.response.status !== 500) {
        return RequestUtil.handleSuccess(error);
      } else {
        return RequestUtil.handleError(error);
      }
    })
  }

  static get(path, params = {}, message = null) {
    return api.get(path, {
      baseURL: process.env.NUXT_ENV_BASE_API,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type':  'application/json',
      },
      params
    }).then(function (response) {
      return RequestUtil.handleSuccess(response, message);
    }).catch(function (error) {
      if (error.response && error.response.status !== 500) {
        return RequestUtil.handleSuccess(error.response.data, message);
      } else {
        return  RequestUtil.handleError(error);
      }
    })
  }

  static put(path, body, message = null) {
    return api.put(path, body, {
      baseURL: process.env.NUXT_ENV_BASE_API,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type':  'application/json',
      }
    }).then(function (response) {
      return RequestUtil.handleSuccess(response, message);
    }).catch(function (error) {
      if (error.response && error.response.status !== 500) {
        return RequestUtil.handleSuccess(error.response.data, message);
      } else {
        return RequestUtil.handleError(error);
      }
    })
  }

  static delete(path, message = null) {
    return api.delete(path, {
      baseURL: process.env.NUXT_ENV_BASE_API,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type':  'application/json',
      }
    }).then(function (response) {
      return RequestUtil.handleSuccess(response, message);
    }).catch(function (error) {
      if (error.response && error.response.status !== 500) {
        return RequestUtil.handleSuccess(error.response.data, message);
      } else {
        return RequestUtil.handleError(error);
      }
    })
  }

  static handleError(error) {
    if (error.response === undefined) {
      return {
        status: 500,
        data: {}
      };
    }
    if (error.response.status === 401) {
      removeToken();
    }
    if (error.response) {
      return {
        status: error.response.status,
        message: error.response.message,
        data: error.response.data
      };
    }
    return {
      status: error.response.status,
      message: error.response.message,
      data: error.response.data
    };
  }

  static handleSuccess(response, message) {
    if (response.status === 401) {
      removeToken();
    }
    return {
      status: response.status,
      message: response.message,
      data: response.data
    };
  }
};
