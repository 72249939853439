
import Header from "@/components/commons/Header";
import Footer from "@/components/commons/Footer";
import GoogleAdsense from "~/components/GoogleAdsense.vue";

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    GoogleAdsense,
  },
  methods: {
    upTop() {
      window.scrollTo(0, 0);
    }
  }
}
