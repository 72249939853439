
import RequestUtil from "@/utils/request-util";
import {loginForm} from "@/rules/auth/loginForm";
import {registerForm} from "@/rules/auth/registerForm";
import Menu from "@/components/user/Menu";
import {
  getAdminStatus,
  getAvatar,
  getName,
  getToken,
  removeToken,
  setAdminStatus,
  setToken,
  setUserInfo
} from "@/utils/auth";
import Notification from "@/components/commons/Notification.vue";
import Loading from "@/components/commons/Loading.vue";
import Loading2 from "@/components/commons/Loading2.vue";

export default {
  components: {
    Loading2,
    Loading,
    Notification,
    Menu
  },
  data() {
    return {
      notificationStatus: false,
      animationClass: '',
      logged: false,
      name: "",
      avatar: null,
      passwordFieldType: 'password',
      searchStatus: false,
      subMenuStatus: false,
      navMobileStatus: false,
      mobileShow: true,
      categories: [],
      subCategories: [],
      categoryMobile: [],
      categoryAll: [],
      registerModal: false,
      loginModal: false,
      categoryFixed: false,
      notificationCountNew: 0,
      forgotActiveTab: 1,
      forgotLoading: false,
      forgotOtpStatus: false,
      forgotOtpMessage: "",
      loginBody: {
        username: "",
        password: ""
      },
      forgotPasswordBody: {
        email: "",
        otp: "",
        password: "",
      },
      searchBody: {
        categoryIds: [],
        keyword: null,
      },
      registerBody: {
        username: "",
        password: ""
      },
      rules: loginForm,
      rulesRegister: registerForm,
    }
  },

  mounted() {
    this.isAdmin = getAdminStatus();
    this.fetchCategory(this.registerBody);
    this.getCountNotificationNew();
    let token = getToken();
    this.name = getName();
    this.avatar = getAvatar();
    this.logged = !(token === null || token === undefined);
    document.addEventListener('click', this.handleClickOutside);

  },
  created() {
    if (process.browser) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    getAvatar,
    doSearch() {
      const categoryIdsString = this.searchBody.categoryIds.join(',');
      this.$router.push({ path: '/tim-kiem', query: { categoryIds: categoryIdsString, keyword: this.searchBody.keyword} });
      this.$bvModal.hide("modal-search")
    },
    addOrRemoveCategoryId(id) {
      const index = this.searchBody.categoryIds.indexOf(id);
      if (index === -1) {
        // Nếu item không có trong mảng, thêm vào
        this.searchBody.categoryIds.push(id);
      } else {
        // Nếu item có trong mảng, loại bỏ
        this.searchBody.categoryIds.splice(id, 1);
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    toggleMenu() {
      this.mobileShow = !this.mobileShow;
    },
    handleClickOutside(event) {
      const targetElement = event.target;
      if (!targetElement.closest('#notification_tab', []) && !targetElement.closest('.btn-notification', [])) {
        this.notificationStatus = false;
      }
    },
    async resetPassword() {
      const res = await RequestUtil.post('api/v1/auth/forgot_password', this.forgotPasswordBody);
      if (res.status === 200) {
        this.$notify({
          title: 'Thành công',
          type: 'success',
          text: 'Bạn có thể đăng nhập'
        });
      } else {
        this.$notify({
          title: 'Không thành công',
          type: 'warn',
          text: 'Bạn vui lòng nhập lại'
        });
      }
      this.$bvModal.hide("modal-forgot-password");
    },
    async sendOtpVerify(event) {
      this.forgotPasswordBody.email = this.forgotPasswordBody.email.toLowerCase()
      this.forgotPasswordBody.email = this.forgotPasswordBody.email.replace(/\s/g, "");
      this.forgotLoading = true;
      this.forgotOtpStatus = false
      if (!this.isValidEmail(this.forgotPasswordBody.email)) {
        this.$notify({
          title: 'Email không đúng định dạng',
          type: 'warn',
          text: 'Bạn vui lòng nhập lại'
        });
        this.forgotLoading = false;
        return
      }
      const res = await RequestUtil.post('api/v1/auth/send_otp_forgot_password', this.forgotPasswordBody);
      if (res.status === 200) {
        this.forgotActiveTab = 1;
        this.forgotLoading = false;
      } else {
        this.$notify({
          title: res.message,
          type: 'warn',
          text: 'Bạn vui lòng nhập lại'
        });
        this.forgotActiveTab = 0;
        this.forgotLoading = false;
      }

    },
    async checkOtp(event) {
      this.forgotOtpStatus = false
      const res = await RequestUtil.post('api/v1/auth/check_otp_code', this.forgotPasswordBody);
      if (res.status === 200) {
        this.forgotOtpStatus = true
        this.forgotOtpMessage = "";
      } else {
        this.forgotOtpStatus = false
        this.forgotOtpMessage = res.message;
      }
    },
    async showNotification() {
      this.notificationStatus = !this.notificationStatus;
      if (this.notificationStatus) {
        this.animationClass = 'animate__animated animate__fadeInRight'
      }

    },
    async showForgotPassword() {
      this.$bvModal.hide("modal-login");
      this.$bvModal.hide("modal-register");
      this.$bvModal.show("modal-forgot-password");
    },
    async getCountNotificationNew() {
      const res = await RequestUtil.get('api/v1/notification/countNew');
      if (res.status === 200) {
        this.notificationCountNew = res.data
      } else {
        console.log("lỗi gọi notification")
      }

    },

    logout() {
      removeToken();
      this.logged = false;
      window.location.href = '/'
      this.$localStorage.removeAccountPackage();
    },
    async loginSubmit() {
      this.loginBody.username = this.loginBody.username.toLowerCase()
      this.loginBody.username = this.loginBody.username.replace(/\s/g, "");
      this.loginBody.password = this.loginBody.password.replace(/\s/g, "");
      const response = await RequestUtil.post('api/v1/login', this.loginBody);
      this.pushByResponse(response)

      if (response.status === 200) {
        setToken(response.data.token);
        setUserInfo(response.data.info)
        setAdminStatus(response.data.info)
        this.name = getName()
        this.avatar = getAvatar()
        this.logged = true;
        this.isAdmin = getAdminStatus();
        this.$bvModal.hide('modal-login')
        //
        if (process.client) {
          this.$localStorage.setAccountPackage(response.data.info.type, response.data.info.expiryTimePackage);
        }
      }
    },
    async registerSubmit() {
      if (!this.isValidEmail(this.registerBody.username)) {
        this.$notify({
          title: 'Email không đúng định dạng',
          type: 'warn',
          text: 'Bạn vui lòng nhập lại'
        });
        return
      }
      if (this.registerBody.password !== this.registerBody.repassword) {
        this.$notify({
          title: 'Mật khẩu không trùng khớp',
          type: 'warn',
          text: 'Bạn vui lòng nhập lại'
        });
        return
      }
      if (this.registerBody.password.length < 4) {
        this.$notify({
          title: 'Mật khẩu phải có ít nhất 4 ký tự',
          type: 'warn',
          text: 'Bạn vui lòng nhập lại'
        });
        return

      }
      this.registerBody.username = this.registerBody.username.toLowerCase()
      this.loginBody.username = this.loginBody.username.replace(/\s/g, "");
      this.loginBody.password = this.loginBody.password.replace(/\s/g, "");
      const response = await RequestUtil.post('api/v1/register', this.registerBody);
      if (response.status === 200) {
        this.$bvModal.hide('modal-register')
        setToken(response.data.token);
        setUserInfo(response.data.info)
        setAdminStatus(response.data.info)
        this.name = getName()
        this.avatar = getAvatar()
        this.logged = true;
        this.isAdmin = getAdminStatus();
        this.$notify({
          title: 'Đăng ký thành công',
          type: 'success',
          text: 'Bạn vui lòng nhập lại'
        });
      } else {
        this.$notify({
          title: response.message,
          type: 'warn',
          text: 'Bạn vui lòng nhập lại'
        });
      }
    },
    pushByResponse(response, title) {
      let text = title == null ? response.message : title;
      console.log(response);
      if (response.status === 200) {
        this.$notify({
          title: 'Thành công',
          type: 'success',
          text: text
        });
      } else if (response.status === 500) {
        this.$notify({
          title: 'Lỗi hệ thống',
          type: 'error',
          text: 'Bạn vui lòng tải lại trang'
        });
      } else if (response.status === 401) {
        this.$notify({
          title: text,
          type: 'warn',
          text: 'Bạn vui lòng thử lại'
        });
      } else if (response.status === 400) {
        this.$notify({
          title: text,
          type: 'warn',
          text: 'Bạn vui lòng nhập lại'
        });
      } else {
        this.$notify({
          title: 'Lỗi không xác định',
          type: 'warn',
          text: 'Bạn vui lòng tải lại trang'
        });
      }
    },
    handleScroll(event) {
      // this.categoryFixed = event.srcElement.defaultView.pageYOffset > 180
    },
    async fetchCategory() {
      const categoryResponse = await RequestUtil.get('api/v1/category');
      if (categoryResponse.status === 200) {
        this.categories = categoryResponse.data.category

        this.subCategories = categoryResponse.data.sub_category

        this.categoryMobile = this.categoryMobile.concat(this.categories)

        this.categoryMobile = this.categoryMobile.concat(this.subCategories)


        await this.$store.dispatch('category', this.categoryMobile)
      } else {
        this.$notify({
          title: 'Đã có lỗi xảy ra',
          type: 'error',
          text: 'Vui lòng tải lại trang'
        });
      }
    },
    toggleSearch() {
      this.searchStatus = !this.searchStatus;
    },

    login() {
      this.$refs[""].validate((valid) => {
        if (!valid) {
          return false
        }
        alert("dang nhap thoi")
      })
    }
  }
}
