import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default () => new Vuex.Store({
  state: {
    headerShow: true,
    titlePage: null,
    statusCategory: true,
    statusLoading: false,
    loginModal: false,
    categories: [],
  },
  mutations: {
    hideHeader(state) {
      state.headerShow = false;
    },
    setTitlePage(state, title) {
      state.titlePage = title;
    },
    showHeader(state) {
      state.headerShow = true;
    },
    statusCategory(state, status) {
      state.statusCategory = status;
    },
    category(state, categories) {
      state.categories = categories;
    },
    changeStateLoginModal(state, status) {
      state.loginModal = status;
    }
  },
  actions: {
    hideHeader(context) {
      context.commit('hideHeader')
    },
    setTitlePage({ commit }, title) {
      commit('setTitlePage', title);
    },
    showHeader(context) {
      context.commit('showHeader')
    },
    category(context, categories) {
      context.commit('category', categories)
    },
    statusCategory(context, status) {
      context.commit('statusCategory', status)
    },
    statusLoading(context, status) {
      context.commit('statusLoading', status)
    },
    changeStateLoginModal(context, status) {
      context.commit('changeStateLoginModal', status)
    },

  }
});
