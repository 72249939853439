export const loginForm = {
    username: [
        {
            required: true,
            message: "Email không được để trống",
            trigger: 'blur'
        },
        {
            email: true,
            message: "Định dạng không đúng",
            trigger: 'blur'
        }
    ],
    password: [
        {
            required: true,
            message: 'Mật khẩu tối thiểu 4 ký tự',
            trigger: 'blur'
        }
    ]
}
